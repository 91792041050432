@use '@/styles/utils/mixins.scss' as *;

.successCardsWrapper {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  @include tab() {
    margin-top: 5.125rem;
  }

  .successCardsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-top: 5rem;

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
